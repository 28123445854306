import { ReactNode } from 'react';
import AppHeader from './AppHeader';
import { Box } from '@chakra-ui/react';
import '../static/styles/layout.css'

export function MainLayout({ children }: { children: ReactNode }) {
  return (
    <Box
      flexDirection={'column'}
      position={'relative'}
      display={'flex'}
      flex={1}
      minH={'200vh'}
    >
      <AppHeader />
      {children}
    </Box>
  );
}
