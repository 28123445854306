import { Box } from '@chakra-ui/react';
import Partners from './Partners/Partners';
import Audits from './Audits';
import Zero from "./Zero";
import HeroSection from "./HeroSection";
import Metrics from "./Metrics";
import Footer from "./Footer";

const Home = () => {
  return (
    <Box>
      <HeroSection/>
      <Metrics/>
      <Zero/>
      <Partners />
      <Audits />
      <Footer/>
    </Box>
  );
};

export default Home;
