import { Route, Routes } from 'react-router-dom';
import Home from '../pages/index.page';

export const Navigation = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Home />} />
    </Routes>
  );
};

export default Navigation;
