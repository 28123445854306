import { Box, Link } from '@chakra-ui/react';
import ZeroLogo from '../static/logo/zerolend-logo-lg.svg';
import Button from "../component/Button";

export default function AppHeader() {
  return (
    <Box position={'fixed'} top={{ base: 30, md: 50 }} w={'100vw'} zIndex={999}>
      <Box
        h={{ base: 74, md: 84 }}
        maxW={{ base: 'calc(100vw - 48px)', md: 1040 }}
        borderRadius={{ base: 37, md: 42 }}
        p={4}
        mx={'auto'}
        className={'theme-box-shadow'}
        w={'100%'}
        backdropFilter={'blur(50px)'}
        bgGradient={`linear-gradient(135.86deg, rgba(255, 255, 255, 0.032) 5.24%, rgba(255, 255, 255, 0.02) 51.01%),
         linear-gradient(192.4deg, rgba(255, 255, 255, 0.32) -150.62%, rgba(255, 255, 255, 0.32) 102.87%)`}
      >
        <Box
          height={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'baseline'}
          >
            <img src={ZeroLogo} alt={'Zerolend-logo'} />
          </Box>
          {/*<Box display={{base: 'none', md: 'flex'}}>
            <Link href={'https://docs.zerolend.xyz'} target={'_blank'} _hover={{textDecoration: 'none', opacity: 0.8}} px={4}>
              <Text fontSize={14}>Benefit</Text>
            </Link>
            <Link href={'https://docs.zerolend.xyz'} target={'_blank'} _hover={{textDecoration: 'none', opacity: 0.8}} px={4}>
              <Text fontSize={14}>How it works</Text>
            </Link>
            <Link href={'https://docs.zerolend.xyz'} target={'_blank'} _hover={{textDecoration: 'none', opacity: 0.8}} px={4}>
              <Text fontSize={14}>Pricing</Text>
            </Link>
          </Box>*/}
          <Link href={'https://app.zerolend.xyz/stake'} target={'_blank'}>
            <Button variant={'outline'}>Stake ZERO</Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
