import {Box, Text} from "@chakra-ui/react";
import Container from "../../layouts/Container";
import useGetMetrics from "../../hooks/state/useGetMetrics";

const Metrics = () => {
  const metrics = useGetMetrics();

  return (
    <Box pb={24} pt={{base: 24, md: 0}}>
      <Container>
        <Text
          fontSize={24}
          fontWeight={500}
          opacity={0.6}
          textAlign={'center'}
          w={'100%'}
          mb={8}
        >
          Metrics
        </Text>
        <Box display={'flex'} flexDir={{base: 'column', md: 'row'}}>
          <Box flex={0.33} px={3} mb={{base: 6, md: 0}}>
            <Box
              height={{base: '88px', md: '136px'}}
              textAlign={'center'}
              width={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              borderRadius={'68px'}
              className={'theme-box-shadow'}
            >
              <Box>
                <Text fontSize={{base: 24, md: 42}} mb={{base: 1, md: 2}}>
                  {Intl.NumberFormat('en-US', {
                    maximumFractionDigits: 0,
                  }).format(metrics.marketSize)}
                </Text>
                <Text fontSize={{base: 16, md: 20}} opacity={0.4}>
                  Market Size
                </Text>
              </Box>
            </Box>
          </Box>
          <Box flex={0.33} px={3} mb={{base: 6, md: 0}}>
            <Box
              height={{base: '88px', md: '136px'}}
              textAlign={'center'}
              width={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              borderRadius={'68px'}
              className={'theme-box-shadow'}
            >
              <Box>
                <Text fontSize={{base: 24, md: 42}} mb={{base: 1, md: 2}}>
                  {Intl.NumberFormat('en-US', {
                    maximumFractionDigits: 0
                  }).format(metrics.tvl)}
                </Text>
                <Text fontSize={{base: 16, md: 20}} opacity={0.4}>
                  Total Value Locked
                </Text>
              </Box>
            </Box>
          </Box>
          <Box flex={0.33} px={3} mb={{base: 6, md: 0}}>
            <Box
              height={{base: '88px', md: '136px'}}
              textAlign={'center'}
              width={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              borderRadius={'68px'}
              className={'theme-box-shadow'}
            >
              <Box>
                <Text fontSize={{base: 24, md: 42}} mb={{base: 1, md: 2}}>
                  {Intl.NumberFormat('en-US', {
                    maximumFractionDigits: 0
                  }).format(metrics.uniqueAddress)}
                </Text>
                <Text fontSize={{base: 16, md: 20}} opacity={0.4}>
                  Unique Active Addresses
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Metrics;