import {Box, HStack, Image, Link, Text} from '@chakra-ui/react';
import immunefi from '../../static/audits/immunefi.svg';
import chaos from '../../static/audits/chaos.svg';
import mandus from '../../static/audits/manuds-security.svg';
import cantina from '../../static/audits/cantina.svg';
import peckshield from '../../static/audits/peckshield.svg';
import halborn from '../../static/audits/halborn.svg';
import intotheblock from '../../static/audits/intotheblock.svg';
import hypernative from '../../static/audits/hypernative.png';
import Container from '../../layouts/Container';
import { FaArrowRightLong } from 'react-icons/fa6';
import zero3DRight from '../../static/vectors/zero-3d-right.png';
import zero3DLeft from '../../static/vectors/zero-3d-left.png';


const Audits = () => {
  return (
    <Box pb={56}>
      <Container>
        <Box
          w={'100%'}
          minH={{base: 'auto', md: '296px'}}
          height={'auto'}
          bg={'#0E0E0E'}
          borderRadius={'2xl'}
          overflow={'hidden'}
          position={'relative'}
          p={{base: 8, md: 0}}
          py={{base: 8, md: 8}}
        >
          <Image src={zero3DRight} position={'absolute'} right={0} opacity={0.08} display={{base: 'none', md: 'block'}}/>
          <Image src={zero3DLeft} position={'absolute'} left={0} opacity={0.08} display={{base: 'none', md: 'block'}}/>
          <Box
            display={{base: 'block', md: 'flex'}}
            alignItems={'center'}
            justifyContent={'center'}
            height={'100%'}
            zIndex={999}
            position={'relative'}
          >
            <Box textAlign={{base: 'left', md: 'center'}}>
              <Text fontSize={'28px'} fontWeight={'bold'} mb={{base: 4, md: 12}}>
                Audits and Risks
              </Text>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={{base: 'flex-start', md: 'center'}}
                mb={{base: 4, md: 12}}
                px={8}
                flexWrap={{base: 'wrap', md: 'wrap'}}
              >
                <Link px={{base: 0, md: 6}} mx={{base: 0, md: 2}} mr={{base: 6, md: 2}} my={{base: 4, md: 4}} href={'https://immunefi.com/'} target={'_blank'}>
                  <Image src={immunefi} alt={'immunefi'} w={{ base: '120px', md: '150px' }} filter={'grayScale(1)'}/>
                </Link>
                <Link px={{base: 0, md: 6}} mx={{base: 0, md: 2}} mr={{base: 6, md: 2}} my={{base: 4, md: 4}} href={'https://chaoslabs.xyz/'} target={'_blank'}>
                  <Image src={chaos} alt={'chaos'} w={{ base: '120px', md: '150px' }} filter={'grayScale(1)'}/>
                </Link>
                <Link px={{base: 0, md: 6}} mx={{base: 0, md: 2}} mr={{base: 6, md: 2}} my={{base: 4, md: 4}} href={'https://mundus.dev/'} target={'_blank'}>
                  <Image src={mandus} alt={'mandus'} w={{ base: '120px', md: '150px' }} filter={'grayScale(1)'}/>
                </Link>
                <Link px={{base: 0, md: 6}} mx={{base: 0, md: 2}} mr={{base: 6, md: 2}} my={{base: 4, md: 4}} href={'https://cantina.xyz/welcome'} target={'_blank'}>
                  <Image src={cantina} alt={'cantina'} w={{ base: '120px', md: '150px' }} filter={'grayScale(1)'}/>
                </Link>
                <Link px={{base: 0, md: 6}} mx={{base: 0, md: 2}} mr={{base: 6, md: 2}} my={{base: 4, md: 4}} href={'https://peckshield.com/'} target={'_blank'}>
                  <Image src={peckshield} alt={'peckshield'} w={{ base: '120px', md: '150px' }} filter={'grayScale(1)'}/>
                </Link>
                <Link px={{base: 0, md: 6}} mx={{base: 0, md: 2}} mr={{base: 6, md: 2}} my={{base: 4, md: 4}} href={'https://www.halborn.com/'} target={'_blank'}>
                  <Image src={halborn} alt={'halborn'} w={{ base: '120px', md: '150px' }} filter={'grayScale(1)'}/>
                </Link>
                <Link px={{base: 0, md: 6}} mx={{base: 0, md: 2}} mr={{base: 6, md: 2}} my={{base: 4, md: 4}} href={'https://www.hypernative.io/'} target={'_blank'}>
                  <Image src={hypernative} alt={'hypernative'} w={{ base: '120px', md: '150px' }} filter={'grayScale(1)'}/>
                </Link>
                <Link px={{base: 0, md: 6}} mx={{base: 0, md: 2}} mr={{base: 6, md: 2}} my={{base: 4, md: 4}} href={'https://www.intotheblock.com/'} target={'_blank'}>
                  <Image src={intotheblock} alt={'intotheblock'} w={{ base: '120px', md: '150px' }} filter={'grayScale(1)'}/>
                </Link>
              </Box>
              <Link
                fontWeight={600}
                mx={{base: 0, md: 'auto'}}
                w={'max-content'}
                display={'block'}
              >
                <HStack>
                  <Link href={"https://docs.zerolend.xyz/security/audits"} target={'_blank'}><Text>View Report</Text></Link>
                  <FaArrowRightLong />
                </HStack>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Audits;
