import { ReactNode } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface IProps {
  children: ReactNode;
}

const Container = (props: IProps & BoxProps) => {
  return (
    <Box
      px={{ base: 4, md: 6 }}
      maxWidth={{ base: '100%', md: '1240' }}
      width={'100vw'}
      ml={'auto'}
      mr={'auto'}
      height={'100%'}
      overflowX={'hidden'}
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default Container;
