import React, { ReactNode } from 'react';

import '../App.css';

import { ChakraProvider } from '@chakra-ui/react';
import { customTheme } from '../static/ChakraTheme';
import { HashRouter as Router } from 'react-router-dom';
import Navigation from '../navigation';

export function Providers({ children }: { children: ReactNode }) {
  return <ChakraProvider theme={customTheme}>{children}</ChakraProvider>;
}

export default function App() {
  return (
    <Providers>
      <Router>
        <Navigation />
      </Router>
    </Providers>
  );
}
