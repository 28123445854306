import { IIndividualPartner } from './Partners';
import { Box, Image } from '@chakra-ui/react';

interface IProps {
  data: IIndividualPartner[];
}

const MobilePartners = (props: IProps) => {
  const { data } = props;
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      maxW={'100vw'}
      flexWrap={{ base: 'wrap' }}
      mt={6}
    >
      {data.map((_d, index) => {
        return (
          <Box
            // href={_d.link}
            px={{ base: 0, md: 6 }}
            mx={{ base: 3, md: 2 }}
            py={{ base: 2, md: 0 }}
            my={{ base: 2, md: 0 }}
          >
            <Image
              src={_d.logo}
              alt={_d.name}
              w={{ base: '120px', md: '150px' }}
              filter={'grayScale(1)'}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default MobilePartners;
