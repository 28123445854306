import Container from '../../layouts/Container';
import { Box, Image, Link, Text } from '@chakra-ui/react';
import Button from '../../component/Button';
import { FaArrowRightLong } from 'react-icons/fa6';
import zero3DRight from '../../static/vectors/zero-3d-right.png';
import zero3DLeft from '../../static/vectors/zero-3d-left.png';
import zero3DMobile from '../../static/vectors/zero-3d-mo.png';

const Zero = () => {
  return (
    <Box pb={40}>
      <Container>
        <Text
          fontSize={{ base: '100px', md: '302px' }}
          className={'zero-gradient-text'}
          textAlign={'center'}
          fontWeight={600}
          opacity={0.6}
          zIndex={-1}
          position={'relative'}
        >
          $ZERO
        </Text>
        <Box
          bgGradient={{
            base: 'linear-gradient(92.84deg, #6459E3 50.06%, #BAA7ED 124.37%)',
            md: 'linear-gradient(to-r, #867CFF -1.22%, #AE8AFF 99.86%)',
          }}
          height={360}
          mt={{ base: '-40px', md: '-156px' }}
          borderRadius={'2xl'}
          mb={6}
          overflow={'hidden'}
          position={'relative'}
          p={{ base: 8, md: 0 }}
        >
          <Image
            src={zero3DMobile}
            position={'absolute'}
            right={0}
            bottom={0}
            opacity={0.2}
            display={{ base: 'block', md: 'none' }}
          />
          <Image
            src={zero3DRight}
            position={'absolute'}
            right={0}
            opacity={0.2}
            display={{ base: 'none', md: 'block' }}
          />
          <Image
            src={zero3DLeft}
            position={'absolute'}
            left={0}
            opacity={0.2}
            display={{ base: 'none', md: 'block' }}
          />
          <Box
            w={'100%'}
            h={'100%'}
            display={{ base: 'block', md: 'flex' }}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box
              maxW={'580px'}
              mx={'auto'}
              textAlign={{ base: 'left', md: 'center' }}
            >
              <Text
                textAlign={{ base: 'left', md: 'center' }}
                fontSize={{ base: 26, md: 32 }}
                mb={{ base: 2, md: 4 }}
                fontWeight={600}
                fontFamily={'heading'}
              >
                Introducing $ZERO
              </Text>
              <Text
                textAlign={{ base: 'left', md: 'center' }}
                fontSize={{ base: 16, md: 20 }}
                fontFamily={'heading'}
                mb={{ base: 4, md: 8 }}
              >
                $ZERO is a governance and utility token. $ZERO stakers receive
                voting rights, staking rewards, and potential future incentives
                from other protocols.
              </Text>
              <Link href={'https://app.zerolend.xyz/stake'} target={'_blank'}>
                <Button
                  width={{ base: '192px', md: '340px' }}
                  mx={{ base: 0, md: 'auto' }}
                  fontFamily={'heading'}
                >
                  Stake $ZERO &nbsp; <FaArrowRightLong />
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
        {/*<Box display={'flex'} flexDir={{base: 'column', md: 'row'}} justifyContent={'center'} alignItems={'center'}>
          <Box
            bgGradient={'linear(180deg, #222222 0%, rgba(26, 26, 26, 0) 100%)'}
            flex={0.5}
            p={{base:8, md: 6}}
            mr={{base: 0, md: 3}}
            h={{base: '260px', md: '316px'}}
            borderRadius={{base: 'xl', md: '2xl'}}
          >
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'end'}
              minH={'100%'}
            >
              <Box>
                <Text fontSize={{base: 16, md: 24}} fontWeight={600}>
                  Realizing the value of Real World Assets
                </Text>
                <Text fontSize={{base: 14, md: 18}} opacity={0.6}>
                  Tokenize physical assets like real estate, bonds, bankruptcy
                  claims, IPs to lend/borrow liquid crypto assets.
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            bgGradient={'linear(180deg, #222222 0%, rgba(26, 26, 26, 0) 100%)'}
            flex={0.5}
            p={{base:8, md: 6}}
            ml={{base: 0, md: 3}}
            h={{base: '260px', md: '316px'}}
            borderRadius={{base: 'xl', md: '2xl'}}
            minH={'100%'}
          >
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'end'}
              height={'100%'}
            >
              <Box>
                <Text fontSize={{base: 16, md: 24}} fontWeight={600}>
                  Realizing the value of Real World Assets
                </Text>
                <Text fontSize={{base: 14, md: 18}} opacity={0.6}>
                  Tokenize physical assets like real estate, bonds, bankruptcy
                  claims, IPs to lend/borrow liquid crypto assets.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>*/}
      </Container>
    </Box>
  );
};

export default Zero;
