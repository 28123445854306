import {Button, ButtonProps} from "@chakra-ui/react";

const CButton = (props: ButtonProps) => {

  if (props.variant === 'outline') {
    return (
      <Button
        {...props}
        variant={'outline'}
        height={{base: '42px', md: '52px'}}
        borderRadius={{base: '21px', md: '26px'}}
        color={'white'}
        _hover={{
          bgColor: 'white',
          color: 'black',
        }}
      >
        {props.children}
      </Button>
    );
  } else {
    return (
      <Button
        {...props}
        height={{base: '42px', md: '52px'}}
        borderRadius={{base: '21px', md: '26px'}}
        border={'1px solid white'}
        _hover={{
          bgColor: 'transparent',
          color: 'white',
        }}
      >
        {props.children}
      </Button>
    );
  }
}

export default CButton;